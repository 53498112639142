



























































import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator"
import { getModule } from "vuex-module-decorators"
import LangModule from "@/store/LangModule"
import CartTool from "@/services/tool/CartTool"
import CartModule from "@/store/CartModule"
import Product from "@/models/Product"
import ConstantTool from "@/services/tool/ConstantTool"
import LoginService from "@/services/LoginService"
import SnackbarModule from "@/store/SnackbarModule"
import ProductService from "@/services/ProductService";
import SessionModule from "@/store/SessionModule";

@Component
export default class ProductDialog extends Vue {

  @Prop() readonly dialog!: boolean
  @Prop() readonly product!: Product
	sessionModule: SessionModule = getModule(SessionModule)
	cartModule: CartModule = getModule(CartModule)
	lang: any = getModule(LangModule).lang
	BASE_URL: string = ConstantTool.BASE_URL
	image: string = ""

  get productCategoriesLength() { return this.product.productCategories?.length}

  get isLoged(): boolean { return LoginService.isLoged() }

  created() { this.refresh() }

  addProduct() {
    CartTool.addProduct(this.cartModule, this.product)
    CartTool.refreshCart()
    this.$emit('close')
    this.image = ""
  }

  proceedToCheckout() {
    this.addProduct()
    this.$router.push('/cart')
  }

  errorHandler() {
    this.image = "../assets/ban.svg"
    this.$forceUpdate()
  }

	addFavoriteProduct(product: Product) {
		ProductService.relateFavoriteProduct(this, this.sessionModule.session.user.id!, product.id!)
	}

  refresh() {
    this.image = `${this.BASE_URL}/storage/ftp/art_${this.product.connectorId?.toLowerCase()}_1_tmb.jpg`
  }

  addFavourite() {
    if (this.isLoged) {
      getModule(SnackbarModule).makeToast(`El producto ha sido marcado como favorito.`)
    } else {
      this.$router.push("/login")
    }
  }
}
