















import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator"
import { getModule } from "vuex-module-decorators"
import LangModule from "@/store/LangModule"
import ProductCategory from "@/models/ProductCategory"

@Component
export default class CategoriesTreeviewComponent extends Vue {
	@Prop() readonly productCategories!: ProductCategory[]
	@Prop() readonly loading!: boolean
	lang: any = getModule(LangModule).lang
	selectedCategories: ProductCategory[] = []

	@Watch('selectedCategories')
	onSelectedCategoriesChanged() {
		this.$emit("selected-categories", this.selectedCategories)
	}

}
